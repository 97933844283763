import React, { useState, useEffect } from 'react';
import Header from "../components/Header/Header";
import './css.css';

import Pages from "../components/pages";
import { Link } from "react-router-dom";
import Get from '../Fech/Get';
import QuestionForm from '../components/firstQuestion/QuestionForm'
import QuestionNoform from '../components/afterQuestion/QestionNoForm2';

function QuestionPage(props) {
    const submit = 'submit';
    const [title, setTitle] = useState('Hi! Let’s start from simple question');
    const [question, setQuestion] = useState({
        question_name: '',
        respondents: '',
        answers: [],
        reply: null,
        question_hash: ''

    });
    const getContent = async () => {
        var urlParams = new URLSearchParams(window.location.search);
        let order_id = urlParams.get('id');
        if (order_id) {
            let loaded = document.querySelector(".loaded-block")
            loaded.classList.add("loaded");
            let data = await Get.post('get_question', {
                key: Get.key,
                hash: Get.userhash,
                question_id: order_id
            });
            console.log(data);
            loaded.classList.remove("loaded");

            setQuestion({
                question_name: data.question_name,
                respondents: data.respondents,
                answers: data.answers,
                reply: data.reply,
                tags: data.tags,
                favorites: data.favorites,
                id: data.question_id,
                question_hash: data.question_hash,
                question_start: data.question_start

            });
        }
    }
    const submitForm = async (e) => {
        e.preventDefault();
        var urlParams = new URLSearchParams(window.location.search);
        let order_id = urlParams.get('id');
        if (order_id) {
            let loaded = document.querySelector(".loaded-block")
            loaded.classList.add("loaded");
            let formEntries = new FormData(e.target).entries();
            let data = Object.assign(...Array.from(formEntries, ([x, y]) => ({ [x]: y })));
            data.key = Get.key;
            data.hash = Get.userhash;
            data.question_id = order_id;
            let result = await Get.post('save_question', data);
            //console.log(result);
            loaded.classList.remove("loaded");
            setQuestion({
                ...question,
                reply: 1
            })
            setTitle(`Perfect! Let’s see what answered
people around the World.
And what are the people`)
        }
    }
    useEffect(
        () => {
            const as = async () => {
                await getContent();

            }
            as();
        }, []
    );
    return (
        <>

            {Number(question.question_start) !== 1 ? <Header></Header> : <div class="RegistrationContentTitle">{title}</div>}
            <div className='loaded-block'>
                {question.reply == 0 ? <QuestionForm question={question} submitForm={(e) => submitForm(e)} /> : ''}
                {question.reply == 1 ? <QuestionNoform question={question} /> : ''}
            </div>

        </>
    );
}

export default QuestionPage;