import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, Link } from 'react-router-dom';
import './RegistrationContent.css';
import Get from '../../Fech/Get';
function Migrate(props) {
    const [error, setError] = useState('');
    const getKey = async (e) => {
        e.preventDefault();
        e.target.classList.add("loaded");
        let formEntries = new FormData(e.target).entries();
        let data = Object.assign(...Array.from(formEntries, ([x, y]) => ({ [x]: y })));
        data.key = Get.key;
        let result = await Get.post('get_user', data);
        e.target.classList.remove("loaded");
        if (result !== null && result.hasOwnProperty('hash')) {
            let page = '/';
            let question = await Get.post('get_questions_list_start', data);
            if (Array.isArray(question)) {
                page = `/questionpage?id=${question[0].id}`;
            }
            localStorage.setItem("hash", result.hash);
            window.location.href = page;
        } else {
            setError('There is no such key available.')
        }
    }
    return (
        <div className={"RegistrationContent"}>
            <form onSubmit={(e) => { getKey(e) }}>
                <div className={"RegistrationContentTitle"}>Migrate from other device</div>
                <div className="container">

                    <label className='label-key' htmlFor='KeyMigrate'>Insert your key</label>
                    <div className='pt-20'></div>
                    <input id='KeyMigrate' type="text" required name='hash' placeholder="your key" />
                    <div className='error'>{error}</div>
                    <div className="flex-button-reverce" >
                        <Link className='link-toreverce' to="/">
                            <img src={require('../../images/Frame.svg').default} alt="" />
                        </Link>
                        <button type='submit' className={"RegistrationContentLetsgo"}>let’s go!</button>
                    </div>
                </div>
            </form>
        </div>
    );
}


export default Migrate;