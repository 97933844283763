import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import WorldMap from "react-svg-worldmap";
import Footer from '../Footer/Footer';
import Get from '../../Fech/Get';
const QuestionNoform2 = (props) => {

    const [question, setQuestion] = useState(props.question);
    const setFavorite = async (int) => {
        let action = int == 0 ? 'UnsetFavorites' : 'setFavorites';
        let data = await Get.post('get_favorites', {
            key: Get.key,
            hash: Get.userhash,
            action: action,
            id: question.id

        });

        setQuestion({ ...question, favorites: int });

    }
    const [nametabs, setNametabs] = useState('all');
    const [ansfer, setAnsfer] = useState([]);
    const [miniTabs, setMinitabs] = useState([]);
    const [activetab, setActiveTab] = useState('');
    const [map, setMap] = useState([]);
    const getMap = (answers) => {
        let map = [];
        answers.map((el, index) => {
            if (el.hasOwnProperty('countries')) {
                let countres = Object.keys(el.countries);
                let golos = Object.values(el.countries);
                countres.map((el, index) => {
                    map.push({
                        country: el.toLowerCase().substr(0, 2),
                        value: golos[index]
                    })
                })
            }
        })
        return map;
    }
    const valueAnsfer = (answers) => {
        let dataAnsfer = [];
        if (Array.isArray(answers)) {
            let data = {
                all: 0,
                ansfers: []

            };
            answers.map((el, i) => {
                let datael = {
                    answer: el.answer,
                    total: el.hasOwnProperty('total') && typeof el.total == 'number' ? el.total : 0
                }
                data.all += el.hasOwnProperty('total') && typeof el.total == 'number' ? el.total : 0;
                data.ansfers.push(datael);
            });


            data.ansfers.map((el, i) => {

                let datalist = {
                    answer: el.answer,
                    percent: Math.round((el.total * 100) / data.all)
                }

                dataAnsfer.push(datalist);
            });

        }
        return dataAnsfer;
    }
    const valueMini = (answers, tab) => {
        let dataAnsfer = [];

        if (Array.isArray(answers)) {
            let data = {
                all: 0,
                ansfers: []

            };
            answers.map((el, i) => {
                let datael = {
                    answer: el.answer,
                    total: el.hasOwnProperty(tab) && typeof el[tab] == 'number' ? el[tab] : 0
                }

                data.all += el.hasOwnProperty(tab) && typeof el[tab] == 'number' ? el[tab] : 0;
                data.ansfers.push(datael);
            });
            data.ansfers.map((el, i) => {

                let datalist = {
                    answer: el.answer,
                    percent: data.all !== 0 ? Math.round((el.total * 100) / data.all) : 0
                }

                dataAnsfer.push(datalist);
            });

        }
        return dataAnsfer;
    }
    const setIndexMinitab = (index) => {
        setMinitabs(minit => {
            minit.map((el, i) => {
                if (i == index) {
                    el.active = 'active';
                    setActiveTab(el.field);
                } else {
                    el.active = '';
                }
                return el;
            })
            return [...minit];
        })
    }
    const countAtribute = (tab) => {
        if (tab !== '' && tab !== undefined) {
            let ansfers = valueMini(question.answers, tab);
            setAnsfer(ansfers);
        }

    }
    useEffect(() => {
        countAtribute(activetab);
    }, [activetab]);
    useEffect(() => {
        let ansfers = [];
        let minit = [];
        switch (nametabs) {
            case 'all':
                ansfers = valueMini(question.answers, 'total');
                setAnsfer(ansfers);
                setMinitabs(minit);
                break;
            case 'gender':
                ansfers = valueMini(question.answers, 'male');
                setActiveTab('male');
                minit = [{
                    title: 'male',
                    field: 'male',
                    active: 'active'
                },
                {
                    title: 'female',
                    field: 'female',
                    active: ''
                },
                {
                    title: 'other',
                    field: 'other',
                    active: ''
                }]
                setMinitabs(minit);
                break;
            case 'ages':
                ansfers = valueMini(question.answers, 'age_0_23');
                setActiveTab('age_0_23');
                minit = [{
                    title: 'to 24',
                    field: 'age_0_23',
                    active: 'active'
                },
                {
                    title: '25-45',
                    field: 'age_24_45',
                    active: ''
                },
                {
                    title: 'from 45',
                    field: 'age_46',
                    active: ''
                }]
                setMinitabs(minit);
                break;

            case 'map':
                let map = getMap(question.answers);
                setMap(map)
                break;
        }

    }, [nametabs]);
    return (

        <div className={"main pageQuestion"}>
            <div className={"mainQuestion container"}>{question.question_name}</div>
            <div className={"votesTimeFavorite"}>
                <div>
                    <img src={require('../../images/question.svg').default} alt="" />
                    <pre>{question.respondents} votes</pre>
                </div>
                <div>
                    <img src={require('../../images/time.svg').default} alt="" />
                    yesterday
                </div>
                <div>
                    {question.favorites == 1 ?
                        <span className='favspan' onClick={() => { setFavorite(0) }}><img src={require('../../images/favorite.svg').default} alt="" />in favorites</span> :
                        <span className='favspan' onClick={() => { setFavorite(1) }}><img src={require('../../images/infavorite.svg').default} alt="" />to favorites</span>}
                </div>
            </div>

            <hr />
            <div className="AfterQuestionGeneral">
                <div className="AfterQuestionGeneralImg">
                    {nametabs == 'all' ?
                        <img src={require('../../images/1.svg').default} alt="" /> :
                        <img src={require('../../images/diagram-o.svg').default} alt="" onClick={() => setNametabs('all')} />
                    }
                    {nametabs == 'gender' ?
                        <img src={require('../../images/gender_page.svg').default} alt="" /> :
                        <img src={require('../../images/gender.svg').default} alt="" onClick={() => setNametabs('gender')} />
                    }
                    {
                        nametabs == 'ages' ?
                            <img src={require('../../images/people_page.svg').default} alt="" /> :
                            <img src={require('../../images/people.svg').default} alt="" onClick={() => setNametabs('ages')} />
                    }
                    {
                        nametabs == 'map' ?
                            <img src={require('../../images/point_page.svg').default} alt="" /> :
                            <img src={require('../../images/point.svg').default} alt="" onClick={() => setNametabs('map')} />
                    }
                </div>
            </div>
            {nametabs == 'map' ?
                <div className="AfterQuestionGeneralPercent">
                    <div className="container">
                        <div className="map">
                            <div className="map-container">
                                <WorldMap
                                    color="#6D98BB"
                                    title=""
                                    value-suffix="people"
                                    size="lg"
                                    data={map}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='container'>
                    <div className='mini-tabs flex-center flex-wrap'>
                        {miniTabs.map((el, index) => {
                            return (
                                <div key={index} className={`mini-tab-item ${el.active}`} onClick={() => { setIndexMinitab(index) }}>
                                    {el.title}
                                </div>
                            );
                        })}
                    </div>
                    <div className='ansfer-progress-wrap'>
                        {ansfer.map((el, index) => {
                            return (
                                <div key={index} className='progress-bar'>
                                    <div className='default'><span className="padding">{el.answer} - <span className='bold'>{el.percent} %</span></span></div>
                                    <div className='value' style={{ width: el.percent + '%' }}><span className="padding">{el.answer} - <span className='bold'>{el.percent} %</span></span></div>
                                </div>
                            );
                        })}

                    </div>
                    <div className="flex-button-reverce" >
                        <Link className='link-toreverce' to="/">
                            <img src={require('../../images/Frame.svg').default} alt="" />
                        </Link>
                        {console.log(question)}
                        <a target='_blank' href={`https://blancapp.com/questions?${question.question_hash}`} className={"RegistrationContentLetsgo"}>share question</a>
                    </div>
                </div>
            }
            <Footer></Footer>
        </div>
    );
}
export default QuestionNoform2;